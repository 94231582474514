(function () {
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function deleteAllCookies() {
    let cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
  window.deleteAllCookies = deleteAllCookies;
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  if (document.readyState !== 'loading') {
    preinit();
  } else {
    document.addEventListener('DOMContentLoaded', e => {
      preinit();
    });
  }
  function preinit() {
    console.log('Preinit: Waiting for scripts');
    const interval = setInterval(() => {
      if (typeof jQuery !== 'undefined' && typeof anime !== 'undefined') {
        console.log('Preinit complete');
        clearInterval(interval);
        loadJqueryPlugins();
      }
    }, 33);
  }
  function loadJqueryPlugins() {
    console.log('Loading plugins');
    const plugins = [jsPath + 'jquery.waypoints.min.js', 'https://cdnjs.cloudflare.com/ajax/libs/jquery-touch-events/2.0.3/jquery.mobile-events.js'];
    let numberOfScriptsToLoad = plugins.length;
    for (let i = 0; i < plugins.length; i++) {
      (function () {
        const index = i;
        let script = document.createElement('script');
        script.defer = true;
        script.onload = e => {
          console.log('script loaded', plugins[index]);
          numberOfScriptsToLoad--;
          if (numberOfScriptsToLoad <= 0) {
            console.log('Plugins loaded');
            init();
          }
        };
        script.src = plugins[i];
        document.head.appendChild(script);
      })();
    }
  }
  const hash = window.location.hash;
  if (hash) {
    const target = document.getElementById(hash.substring(1));
    if (target) {
      requestIdleCallback(() => {
        window.scrollTo(0, 0);
        $('html, body').animate({
          scrollTop: $(target).offset().top
        }, 400);
      });
    }
  }
  function throttle(func, delay = 200, options = { leading: false, trailing: true }){
    let timer = null,
        lastRan = null,
        trailingArgs = null;

    return function (...args) {

      if (timer) { //called within cooldown period
        lastRan = this; //update context
        trailingArgs = args; //save for later
        return;
      }

      if (options.leading) {// if leading
        func.call(this, ...args) //call the 1st instance
      } else { // else it's trailing
        lastRan = this; //update context
        trailingArgs = args; //save for later
      }

      const coolDownPeriodComplete = () => {
        if (options.trailing && trailingArgs) { // if trailing and the trailing args exist
          func.call(lastRan, ...trailingArgs); //invoke the instance with stored context "lastRan"
          lastRan = null; //reset the status of lastRan
          trailingArgs = null; //reset trailing arguments
          timer = setTimeout(coolDownPeriodComplete, delay) //clear the timout
        } else {
          timer = null; // reset timer
        }
      }

      timer = setTimeout(coolDownPeriodComplete, delay);
    }
  }

  function hideMenuOnScroll() {
    (function () {
      let lastScrollTop = 0;

      window.addEventListener("scroll", throttle(() => {
        const pos = window.pageYOffset || document.documentElement.scrollTop;

        const speed = pos - lastScrollTop;
        lastScrollTop = pos;

        if (pos > 400) {
          document.querySelector('.header').classList.add("header--condensed");
          document.querySelector('.top-nav__open-hours').classList.add("top-nav__open-hours--condensed");
        } else {
          document.querySelector('.header').classList.remove("header--condensed");
          document.querySelector('.top-nav__open-hours').classList.remove("top-nav__open-hours--condensed");
        }

        if (speed > 100 && pos > 400) {
          document.querySelector('.header').classList.add("header--hidden");
        } else if (speed < -100 || pos < 400) {
          document.querySelector('.header').classList.remove("header--hidden");
        }
      }));
    })();
  }
  function initHamburger() {
    document.querySelector('.hamburger').addEventListener('click', e => {
      e.preventDefault();
      document.querySelector('.mobile-menu').classList.toggle('open');
    });
  }
  function initStandOpen() {
    if (document.querySelector('.open-hours-standalone-title')) {
      document.querySelector('.open-hours-standalone-title').addEventListener('click', e => {
        e.preventDefault();
        document.querySelector('.open-hours-standalone').classList.toggle('open');
      });
    }
  }
  function initAccordions() {
    const elements = document.querySelectorAll('.js-toggle-accordion .handle');
    const allAccordions = document.querySelectorAll('.js-toggle-accordion');
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        element.addEventListener('click', event => {
          event.preventDefault();
          let willOpen = true;
          if (element.closest('.js-toggle-accordion').classList.contains('open')) {
            willOpen = false;
          }
          allAccordions.forEach(el => {
            el.classList.remove('open');
          });
          if (willOpen) {
            element.closest('.js-toggle-accordion').classList.add('open');
          }
        });
      }
    }
  }
  function initAnime(targets = '.logo span') {
    anime({
      targets: targets,
      translateY: [-10, 10],
      delay: anime.stagger(100),
      direction: 'alternate',
      loop: true
    });
  }
  function initWaypoint() {
  }
  function initAsLinks() {
    $('.js-as-link').each(function (index, el) {
      $(el).click(function (e) {
        e.preventDefault();
        window.location.href = $(this).attr('data-href');
      });
      $(el).find('a').click(function (e) {
        e.stopPropagation();
      });
    });
  }
  function initCarousels() {
    // @TODO: add carousel selectors here
    const carousels = $('.carousel-module');
    if (carousels.length > 0) {
      carousels.each((index, el) => {
        let onUpdate = null;
        initCarousel(el, onUpdate, () => {
          const w = document.body.clientWidth;
          const cardWidth = $(el).find('.carousel-item').outerWidth();
          const cardsPerScreen = Math.floor(w / cardWidth);
          return cardsPerScreen;
        });
      });
    }
  }
  function initCarousel(selector, onUpdateFn, getNumCardsPerScreen) {
    const $carousel = $(selector);
    const $carouselInner = $carousel.find('.carousel-inner');
    let cardsPerScreen = getNumCardsPerScreen();
    let lastCardsPerScreen = cardsPerScreen;
    let curPos = 0;
    let numCards = $carousel.find('.carousel-item').length;

    // resize handler
    jQuery(window).resize(function () {
      var n = getNumCardsPerScreen();
      if (n != lastCardsPerScreen) {
        cardsPerScreen = lastCardsPerScreen = getNumCardsPerScreen();
        resetCarousel();
      } else {
        updateCarousel();
      }
    });
    $carousel.find('.button.prev').click(function (e) {
      e.preventDefault();
      if (curPos == 0) {
        return;
      }
      curPos--;
      updateCarousel();
    });
    $carousel.find('.button.next').click(function (e) {
      e.preventDefault();
      if (curPos == numCards - cardsPerScreen) {
        return;
      }
      curPos++;
      updateCarousel();
    });
    if (numCards <= cardsPerScreen) {
      $carousel.find('.button.next').addClass('disabled');
    }
    if ($.isTouchCapable()) {
      $carousel.on('swiperight', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (curPos == 0) {
          return;
        }
        curPos--;
        updateCarousel();
      });
      $carousel.on('swipeleft', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (curPos == numCards - cardsPerScreen) {
          return;
        }
        curPos++;
        updateCarousel();
      });
    }
    function updateCarousel() {
      var step = $carouselInner.find('.carousel-item').outerWidth();
      var translateX = -(step * curPos) | 0;
      var innerWidth = $carouselInner.outerWidth();
      var cardsWidth = numCards * step;
      var maxTranslate = cardsWidth - innerWidth;
      if (translateX < -maxTranslate) {
        translateX = -maxTranslate;
      }
      onUpdateFn && onUpdateFn(curPos + 1);
      $carouselInner.css('transform', 'translateX(' + translateX + 'px)');
      if (curPos == 0) {
        $carousel.find('.button.prev').addClass('disabled');
      } else {
        $carousel.find('.button.prev').removeClass('disabled');
      }
      if (curPos == numCards - cardsPerScreen) {
        $carousel.find('.button.next').addClass('disabled');
      } else {
        $carousel.find('.button.next').removeClass('disabled');
      }
      if (numCards <= cardsPerScreen) {
        $carousel.find('.button.next').addClass('disabled');
      }
    }
    function resetCarousel() {
      curPos = 0;
      updateCarousel();
    }
  }
  function initLocationIndex() {
    let currentFilter = null;
    let allIndexLocationEls = $('.location-index-module a.link');
    $('.location-index-tags a').click(function (e) {
      e.preventDefault();
      const slug = $(this).attr('data-slug');
      $('.location-index-tags a').removeClass('active');
      $(this).addClass('active');
      if (slug == 'all') {
        currentFilter = null;
        $('.location-index-module').removeClass('filtered');
        allIndexLocationEls.show();
        return;
      }
      currentFilter = slug;
      $('.location-index-module').addClass('filtered');
      allIndexLocationEls.each(function (i, el) {
        const tags = $(el).attr('data-tags');
        if (tags && tags.indexOf(currentFilter) > -1) {
          $(el).show();
        } else {
          $(el).hide();
        }
      });
    });
  }
  function initAnnouncement() {
    $('.announcment a.close').click(function (e) {
      e.preventDefault();
      $('body').removeClass('announcment-open');
      setCookie('announcement', window.announcementHash, 7);
    });
    $('.announcment-full a.close').click(function (e) {
      e.preventDefault();
      $('body').removeClass('announcment-full-open');
      setCookie('announcement_full', window.announcementHash, 7);
    });
    $('.announcment-full a.announcement-full-follow').click(function () {
      setCookie('announcement_full', window.announcementHash, 7);
      window.location.href = $(this).attr('href');
    });
  }
  function init() {
    console.log('Init');
    hideMenuOnScroll();
    initAccordions();
    initHamburger();
    initLocationIndex();
    initStandOpen();
    initAnime();
    initWaypoint();
    initAsLinks();
    initCarousels();
    initAnnouncement();
    initShowMoreArticles();
    toggleVideoInHero();
  }
  function loadMore(e, pageNumber) {
    const button = e.target || e;
    const url = new URL(window.location.href);
    url.searchParams.set('paged', pageNumber); 

    $.ajax({
        url: window.location + '&paged=' + pageNumber,
        success: function callback(result) {
          
          let items = $(result).find('.js-more-articles').children();
          if (items.length > 0) {
            $('.js-more-articles').append(items);
            $(button).attr('data-page-number', parseInt(pageNumber) + 1);
            
            // Update the browser URL
            window.history.pushState({}, '', url.toString());
          }
        }
    })

    // Check next page for items and disable button if it's empty
    let nextPageNumber = parseInt(pageNumber) + 1;
    $.ajax({
      url: window.location + '&paged=' + nextPageNumber,
      success: function(data) {
        // Page exists
      },
      error: function(xhr, status, error) {
        if (xhr.status === 404) {
          // Page does not exist, disable button
          $(button).attr('disabled', 'disabled');
          $(button).attr('aria-disabled', 'true');
        }
      },
      statusCode: {
        404: function() {
          // Page does not exist, disable button
          $(button).attr('disabled', 'disabled');
          $(button).attr('aria-disabled', 'true');
        }
      }
    });
  };

  function initShowMoreArticles() {
      const loadMoreButton = $('.js-load-more-button');

      $(loadMoreButton).on('click', function loadClick(e) {
          const pageNumber = loadMoreButton.attr('data-page-number');
          loadMore(e, pageNumber);
      });
  };
  
  function toggleVideoInHero() {

    const mainVideo = $('.js-hero-video-player');
    const videoUrl = mainVideo.attr('data-video-url'); 
    const videoMobileUrl = mainVideo.attr('data-video-mobile-url'); 

    
      if ($(window).width() > 980) {
        mainVideo.append("<source type='video/mp4' src='" + videoUrl + "' />");
      } else {
        mainVideo.append("<source type='video/mp4' src='" + videoMobileUrl + "' />");

      } 
    }
})();
